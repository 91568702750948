import React from "react";
import _ from "lodash";
import { graphql, Link } from "gatsby";
import tw, { styled } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Icon from "@shared/components/Icon";
import Header from "@shared/components/Header";
import Table from "@shared/components/Table";
import { MAIN_COLOR, SITE } from "../config";

const Container = styled.div`
  ${tw`p-5`}
`;
const Heading = styled.h5`
  ${tw`text-[#EC1F27] font-bold my-4 text-center`}
`;
const PlayerCell = styled.div`
  ${tw`flex items-center`}
`;
const PlayerAvatarContainer = styled.div`
  ${tw`min-w-[40px] w-10 h-10 rounded-full overflow-hidden`}
  & > .material-icons {
    ${tw`text-4xl px-0.5`}
  }
`;
const PlayerName = styled.div`
  ${tw`ml-2 font-bold`}
`;
const PlayerNumber = styled.div`
  ${tw`opacity-70 ml-2 text-xs`}
`;
const TableTitle = styled.h6`
  ${tw`text-gray-600 font-bold my-4`}
`;
const TableContainer = styled.div`
  ${tw`pb-2 mt-4 min-h-[50px] overflow-x-auto`}
`;
const CoachContainer = styled.div`
  ${tw`p-5`}
`;
const Title = styled.h4`
  ${tw`
    bg-[#EC1F27] text-white font-bold text-center
    p-2.5
  `}
`;
const CoachItem = styled.div`
  ${tw`mt-8 flex flex-col justify-center md:flex-row`}
`;
const AvatarContainer = styled.div`
  ${tw`w-full md:w-[25%]`}
`;
const ContentContainer = styled.div`
  ${tw`w-full md:w-[75%] md:px-4`}
`;
const CoachTitle = styled.h6`
  ${tw`my-2 text-[#535353]`}
`;
const StyledLink = styled(Link)`
  ${tw`hover:underline hover:text-[#EC1F27]`}
`;

const headers = (name) => [
  {
    Header: name,
    accessor: "name",
    sortType: "basic",
    width: 200,
    Cell: ({ row }) => {
      // return <StyledLink to={row.original.slug}>{row.values.name}</StyledLink>
      return (
        <PlayerCell>
          <PlayerAvatarContainer>
            {_.isNil(row.original.avatar) ? (
              <Icon name="account_circle" />
            ) : (
              <GatsbyImage
                image={getImage(row.original.avatar)}
                style={{ width: "100%", height: "100%" }}
                imgStyle={{ width: "100%", height: "100%" }}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="avatar"
              />
            )}
          </PlayerAvatarContainer>
          <PlayerName>{row.values.name}</PlayerName>
          {!_.isNil(row.original.playerNumber) && (
            <PlayerNumber>{row.original.playerNumber}</PlayerNumber>
          )}
        </PlayerCell>
      );
    },
  },
  // {
  //   Header: "Position",
  //   accessor: row => getPosition(row.position),
  //   sortType: "basic",
  //   width: 124,
  // },
  {
    Header: "Home Town",
    accessor: "homeTown",
    sortType: "basic",
    width: 176,
  },
  {
    Header: "College",
    accessor: "college",
    sortType: "basic",
    width: 283,
  },
  {
    Header: "B/T",
    accessor: (row) => `${row.bats[0]}/${row.throws[0]}`,
    sortType: "basic",
    width: 50,
  },
  {
    Header: "Height",
    accessor: "height",
    sortType: "basic",
    width: 50,
  },
  {
    Header: "Weight",
    accessor: "weight",
    sortType: "basic",
    width: 50,
  },
];

const RosterPage = ({ data, pageContext, location }) => {
  const { seo, hero, tabs, title, coachSectionTitle } =
    data.markdownRemark.frontmatter;

  const coaches = data.coachMds.edges;
  // roster
  const players = data.playerMds.edges.map((edge) => {
    const {
      avatar,
      playerNumber,
      age,
      name,
      weight,
      height,
      team,
      yr,
      position,
      college,
      bats,
      throws,
      homeTown,
    } = edge.node.frontmatter;
    const slug = edge.node.fields.slug;
    return {
      avatar,
      playerNumber,
      age,
      name,
      weight,
      height,
      team,
      yr,
      position,
      college,
      bats,
      throws,
      homeTown,
      slug,
    };
  });

  const pitchers = players.filter(
    (p) =>
      p.position === "Right-handed Pitcher" ||
      p.position === "Left-handed Pitcher"
  );

  const catchers = players.filter((p) => p.position === "Catcher");
  const infields = players.filter((p) => p.position === "Infield");
  const outfields = players.filter((p) => p.position === "Outfield");

  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {title && (
          <Header
            title={title}
            heroData={hero}
            tabsData={tabs}
            color={MAIN_COLOR}
            site={SITE}
          />
        )}
        {/* <Heading>{pageContext.year} ROSTER</Heading> */}

        {/* <TableTitle>Pitchers</TableTitle> */}
        <TableContainer>
          <Table
            items={pitchers}
            headers={headers("Pitchers")}
            color={MAIN_COLOR}
          />
        </TableContainer>

        {/* <TableTitle>Catchers</TableTitle> */}
        <TableContainer>
          <Table
            items={catchers}
            headers={headers("Catchers")}
            color={MAIN_COLOR}
          />
        </TableContainer>

        {/* <TableTitle>Infield</TableTitle> */}
        <TableContainer>
          <Table
            items={infields}
            headers={headers("Infield")}
            color={MAIN_COLOR}
          />
        </TableContainer>

        {/* <TableTitle>Outfield</TableTitle> */}
        <TableContainer>
          <Table
            items={outfields}
            headers={headers("Outfield")}
            color={MAIN_COLOR}
          />
        </TableContainer>
      </Container>
      {/* Coaches */}
      {!_.isEmpty(coaches) && (
        <CoachContainer>
          <Title>{coachSectionTitle}</Title>
          {coaches
            .sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order)
            .map((coach) => {
              const { id, html } = coach.node;
              const { name, avatar, title } = coach.node.frontmatter;
              const img = getImage(avatar);
              return (
                <CoachItem key={id}>
                  <AvatarContainer>
                    <GatsbyImage
                      image={img}
                      alt="avatar"
                      style={{ width: "100%", height: "100%" }}
                      imgStyle={{ width: "100%", height: "100%" }}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                  </AvatarContainer>
                  <ContentContainer>
                    <CoachTitle>
                      {name} - {title}
                    </CoachTitle>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </ContentContainer>
                </CoachItem>
              );
            })}
        </CoachContainer>
      )}
    </Layout>
  );
};

export default RosterPage;

export const pageQuery = graphql`
  query RosterPageQuery($year: Int) {
    markdownRemark(frontmatter: { templateKey: { eq: "roster-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        tabs {
          label
          url
          icon
        }
        title
        coachSectionTitle
      }
    }
    playerMds: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "player-page" }
          yr: { eq: $year }
          team: { regex: "/.*dawgs.*/gi" }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            avatar {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            playerNumber
            age
            name
            weight
            height
            team
            yr
            position
            college
            bats
            throws
            homeTown
          }
        }
      }
    }
    coachMds: allMarkdownRemark(
      filter: {
        frontmatter: {
          mdType: { eq: "coach" }
          yr: { eq: $year }
          team: { regex: "/.*dawgs.*/gi" }
        }
      }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            name
            avatar {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            title
            yr
            order
          }
        }
      }
    }
  }
`;

const getPosition = (position) => {
  let term = "";
  switch (position) {
    case "Right-handed Pitcher":
      term = "RHP";
      break;
    case "Left-handed Pitcher":
      term = "LHP";
      break;
    case "Catcher":
      term = "C";
      break;
    case "Infield":
      term = "INF";
      break;
    case "Outfield":
      term = "OF";
      break;
    default:
      break;
  }
  return term;
};
